body {
  margin: 0;
  font-family: "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

fieldset[tooltip]:hover:after, div[tooltip]:hover:after {
  content: attr(tooltip);
  display: flex;
  position: absolute;
  left: calc(4rem + 4vw);
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  min-width: 10rem;
  z-index: 99;
}
